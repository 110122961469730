import {
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  drawTriangle,
  shuffle,
  placeEraseLeft,
  placeTapeHori,
  placeTapeVert,
  placeSq,
  placeBoldText,
  placeRedo,
  placeUndo,
  drawPoint,
  drawTri,
  setSize,
  labelIt,
  drawDash,
  placeEggs,
  drawLine,
  setWidth,
  drawIntersection,
  drawPerpP,
  drawAngle,
  createSpace,
  createAxes,
  drawArc,
  drawSegment
} from '../Utils';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    var graph =createSpace(-3, 7, -2, 8);
    //graph.suspendUpdate();
    var ax =createAxes(graph);
    ax[0].setAttribute({ticks:{visible:false}});
    ax[1].setAttribute({ticks:{visible:false}});
    // Layering
    graph.options.axis.strokeWidth=0;
    graph.options.layer['image']=17;
    graph.options.layer['text']=19;
    graph.options.layer['line']=16;
    graph.options.layer['point']=17;
    graph.options.layer['glider']=17;
    graph.options.layer['angle']=18;
    graph.options.board.minimizeReflow = 'none';
    graph.options.point.showInfobox =false;
    graph.options.line.highlight=false;
    graph.options.image.highlight=false;
    graph.options.text.highlight=false;
    graph.options.polygon.highlight=false;
//    graph.options.text.display='internal';
    //Make the board responsive
    makeResponsive(graph);
    // Creat titleStyle
    placeLogo(graph);
    placeTitle(graph, 'Trigonometric Identities', '(Sum of Angles)');
////////////////////////////////////////////////////////////////////////////////
    var undo = placeUndo(graph, 'left');
    hoverMe(graph, undo, 'Previous Step', 0, -5);
    ////////////////////////////////////////////////////
    var redo = placeRedo(graph);
    hoverMe(graph, redo, 'Next Step', 0, -5);
/////////////////////////////////////////////////////////////////////////////
    var i = 0;
    var eggs = placeEggs(graph);
    eggs[0].setAttribute({visible:()=>i>=0});
    ////////////////
    eggs[1].setAttribute({visible:()=>i>=1});
    ////////////////
    eggs[2].setAttribute({visible:()=>i>=2});
    //////
    var size_arr = 0.07;

    var tx1 = 2.5;
    var ty1 = 5.5;
    var ty2 = 4.5;
    var maxSteps = 4;
    ///////////////////////////All actions go here////////////////////////////////
    ///////////////////////////////////////////////////////////////////////
 var Pt0 = drawPoint(graph, 0, 0);
 Pt0.setAttribute({fillColor:'white', strokeColor:'black', strokeWidth:3});
 setSize(graph, Pt0, 4);
 var Pt1 = drawPoint(graph, 4, 0);
 setSize(graph, Pt1, 0);
 var Pt2 = drawPoint(graph, 0, 4);
 setSize(graph, Pt2, 0);
 var c1 = drawArc(graph, Pt0, Pt1, Pt2);
 c1.setAttribute({strokeColor: '#5B43FF', strokeWidth: 4, dash:0});
 var PtA =graph.create('glider', [2.7, 1.5, c1], {face:'circle', strokeColor: 'black', strokeWidth:2.5, snapToPoints: false, attractorUnit: 'screen', attractorDistance: 20, size:5, name:'A', label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

 var c2 = graph.create('arc', [Pt0, PtA, Pt1], {visible: false, strokeColor: 'red', strokeWidth: 4});
var PtB =graph.create('glider', [1.5, 2.8, c2], {face:'circle', strokeColor: 'black', strokeWidth:2.5, snapToPoints: false, attractorUnit: 'screen', attractorDistance: 20, size:5, name:'B', label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

var PtD = drawPoint(graph, ()=>PtA.X(), 0);
setSize(graph, PtD, 0);
var PtE = drawPoint(graph, 0, ()=>PtB.Y());
setSize(graph, PtE, 0);
var seg = drawSegment(graph, Pt0, PtA);
/////////////////////////////////////////////////////////////////////////
var PtF = graph.create('perpendicularpoint', [PtB, seg ],{face:'o' , name:'', size:3, strokeColor: 'black', fillColor: '#5B43FF', withLabel:true, fixed:true, visible:false, label:{offset: [-20, 0], fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
///////////////////////////////////////////////////////////////////////
var ptG = graph.create('point', [function(){return PtF.X() }, 0 ],{face:'o' , name:'G', size:3, strokeColor: 'black', fillColor: '#5B43FF', withLabel:true, fixed:true, visible:false, label:{offset: [-5, -20], fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
//////////////////////////////////////////////////////////////////////////////
var ptH = graph.create('point', [function(){return PtF.X() }, function(){return PtB.Y() } ],{face:'o' , name:'', size:3, strokeColor: 'black', fillColor: '#5B43FF', withLabel:true, fixed:true, visible:false, label:{offset: [-5, -20], fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
//////////////////////////////////////////////////////////////////////////////
var pol_1 = graph.create('polygon', [Pt0, PtD, PtA], {visible: true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: '#FF3B3B', strokeWidth: 4}, fillColor: '#FF3B3B', fillOpacity:0.2});
var pol_2 = graph.create('polygon', [Pt0, PtF, PtB], {visible: false, vertices:{visible:false, fixed:true}, borders:{visible:false, strokeColor: '#FF3B3B', strokeWidth: 4}, fillColor: '#FF3B3B', fillOpacity:0.2});
var pol_3 = graph.create('polygon', [Pt0, PtB, PtE], {visible: false, vertices:{visible:false, fixed:true}, borders:{visible:false, strokeColor: '#FF3B3B', strokeWidth: 4}, fillColor: '#FF3B3B', fillOpacity:0.2});
var pol_4 = graph.create('polygon', [Pt0, PtF, ptG], {visible: false, vertices:{visible:false, fixed:true}, borders:{visible:false, strokeColor: '#5B43FF', strokeWidth: 4}, fillColor: '#5B43FF', fillOpacity:0.2});
var pol_5 = graph.create('polygon', [PtF, PtB, ptH], {visible: false, vertices:{visible:false, fixed:true}, borders:{visible:false, strokeColor: '#5B43FF', strokeWidth: 4}, fillColor: '#5B43FF', fillOpacity:0.2});
///////////////////////////////////////////////////////////////////////////
var alpha = graph.create('angle', [PtD, Pt0, PtA],{name:()=> 'α',  visible: true, radius: 0.1, strokeColor:'black', fillColor:'black', label: {color: 'black' ,offset: [0 , 0], fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

var beta = graph.create('angle', [PtA, Pt0, PtB],{name:()=> 'β' ,  visible: false, radius: 0.1, strokeColor:'black', fillColor:'black', label: {color: 'black' ,offset: [0 , 0], fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

var alpha2 = graph.create('angle', [ptH, PtF, PtB],{name:()=> 'α',  visible: false, radius: 0.1, strokeColor:'black', fillColor:'black', label: {color: 'black' ,offset: [-10 , 0], fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

//Texts
var txt1_1 = graph.create('text', [function(){return PtA.X()/2}, -0.05,  'cosα'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
var txt2_1 = graph.create('text', [function(){return PtA.X()+ 0.03}, function(){return PtA.Y()/2},  'sinα'],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);

var txt1_2 = graph.create('text', [function(){return PtB.X() + (PtF.X() - PtB.X())/2 - 0.05 }, function(){return PtF.Y() +(PtB.Y() - PtF.Y())/2},  'sinβ'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}});
////////////////////////////////////////////////////////////
var txt2_2 = graph.create('text', [function(){return PtF.X()/2 }, function(){return PtF.Y()/2 + 0.05},  'cosβ'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}});
/////////////////////////////////////////////////////////
var txt1_3 = graph.create('text', [function(){return PtB.X()/2}, function(){return PtB.Y() + 0.05 },  'cos(α + β)'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
var txt2_3 = graph.create('text', [-0.05, function(){return PtB.Y()/2 },  'sin(α + β)'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);

var txt1_4 = graph.create('text', [function(){return ptG.X()/2}, 0.05,  'cosα cosβ'],{visible: false, fixed: true, strokeColor: '#5B43FF', anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
var txt2_4 = graph.create('text', [function(){return ptG.X()-0.05}, function(){return PtF.Y()/2},  'sinα cosβ'],{visible: false, fixed: true, strokeColor: '#5B43FF', anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);

var txt1_5 = graph.create('text', [function(){return PtF.X() + 0.05}, function(){return PtF.Y() + (ptH.Y() - PtF.Y())/2 },  'cosα sinβ'],{visible: false, fixed: true, strokeColor: '#5B43FF', anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
var txt2_5 = graph.create('text', [function(){return PtB.X() + (ptH.X() - PtB.X())/2 + 0.05}, function(){return PtB.Y() + 0.05 },  'sinα sinβ'],{visible: false, fixed: true, strokeColor: '#5B43FF', anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);

var txt1_6 = graph.create('text', [tx1, ty1,  'sin(α + β)'],{visible: false, highlightStrokeColor: '#FF3B3B', fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
var txt2_6 = graph.create('text', [tx1 + 1.125, ty1,  '='],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
var txt3_6 = graph.create('text', [tx1 + 1.5, ty1,  'sinα cosβ'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
var txt4_6 = graph.create('text', [tx1 + 2., ty1,  '+'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}});
/////////////////////////////////////////////////////////////
var txt5_6 = graph.create('text', [tx1 + 2.5, ty1,  'cosα sinβ'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
///////////////////////////////////////////////////
var txt6_6 = graph.create('text', [tx1, ty2,  'cos(α + β)'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}});
/////////////////////////////////////////////
var txt7_6 = graph.create('text', [tx1 + 1.125, ty2,  '='],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}});
////////////////////////////////////////////////////////////////
var txt8_6 = graph.create('text', [tx1 + 1.5, ty2,  'cosα cosβ'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}});
/////////////////////////////////////////////////////////////////////
var txt9_6 = graph.create('text', [tx1 + 2.0, ty2,  '-'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
////////////////////////////////////////////////////////////////
var txt10_6 = graph.create('text', [tx1 + 2.5, ty2,  'sinα sinβ'],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
//////////////////////////////////////////////////////
txt1_6.on('over', function () {
    txt2_3.setAttribute({strokeColor: '#FF3B3B', fontSize:function(){return Math.round(16*graph.canvasWidth/500.)}},);
});

txt1_6.on('out', function () {
    txt2_3.setAttribute({strokeColor: 'black', fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
});

txt3_6.on('over', function () {
    txt2_4.setAttribute({strokeColor: '#FF3B3B', fontSize:function(){return Math.round(16*graph.canvasWidth/500.)}},);
});

txt3_6.on('out', function () {
    txt2_4.setAttribute({strokeColor: '#5B43FF', fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
});

txt5_6.on('over', function () {
    txt1_5.setAttribute({strokeColor: '#FF3B3B', fontSize:function(){return Math.round(16*graph.canvasWidth/500.)}},);
});

txt5_6.on('out', function () {
    txt1_5.setAttribute({strokeColor: '#5B43FF', fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
});

txt6_6.on('over', function () {
    txt1_3.setAttribute({strokeColor: '#FF3B3B', fontSize:function(){return Math.round(16*graph.canvasWidth/500.)}},);
});

txt6_6.on('out', function () {
    txt1_3.setAttribute({strokeColor: 'black', fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
});

txt8_6.on('over', function () {
    txt1_4.setAttribute({strokeColor: '#FF3B3B', fontSize:function(){return Math.round(16*graph.canvasWidth/500.)}},);
});

txt8_6.on('out', function () {
    txt1_4.setAttribute({strokeColor: '#5B43FF', fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
});

txt10_6.on('over', function () {
    txt2_5.setAttribute({strokeColor: '#FF3B3B', fontSize:function(){return Math.round(16*graph.canvasWidth/500.)}},);
});

txt10_6.on('out', function () {
    txt2_5.setAttribute({strokeColor: '#5B43FF', fontSize:function(){return Math.round(14*graph.canvasWidth/500.)}},);
});
// Rotate text
var tRot1_1 = graph.create('transform', [90*Math.PI/180.0, function(){return PtA.X()+ 0.03}, function(){return PtA.Y()/2} ], {type:'rotate'});
tRot1_1.bindTo(txt2_1);

var tRot1_2 = graph.create('transform', [alpha.Value() - 90*Math.PI/180.0, function(){return PtB.X() + (PtF.X() - PtB.X())/2 - 0.05 }, function(){return PtF.Y() +(PtB.Y() - PtF.Y())/2}], {type:'rotate'});
tRot1_2.bindTo(txt1_2);

var tRot2_2 = graph.create('transform', [alpha.Value() , function(){return PtF.X()/2 }, function(){return PtF.Y()/2 + 0.05}], {type:'rotate'});
tRot2_2.bindTo(txt2_2);

var tRot1_3 = graph.create('transform', [90*Math.PI/180.0, -0.05, function(){return PtB.Y()/2 } ], {type:'rotate'});
tRot1_3.bindTo(txt2_3);

var tRot1_4 = graph.create('transform', [90*Math.PI/180.0,function(){return ptG.X()-0.05}, function(){return PtF.Y()/2}], {type:'rotate'});
tRot1_4.bindTo(txt2_4);

var tRot1_5 = graph.create('transform', [90*Math.PI/180.0,function(){return PtF.X() + 0.05}, function(){return PtF.Y() + (ptH.Y() - PtF.Y())/2 }], {type:'rotate'});
tRot1_5.bindTo(txt1_5);


function updateSteps(){
    eraseEverything();
    if (i<=0){
        pol_1.setAttribute({visible: true, borders:{visible:true}});
        txt1_1.setAttribute({visible: true});
        txt2_1.setAttribute({visible: true});
    }
    if(i==1){
        pol_1.setAttribute({visible: true, borders:{visible:true}});
        pol_2.setAttribute({visible: true, borders:{visible:true}});
        txt1_1.setAttribute({visible: true});
        txt2_1.setAttribute({visible: true});
        txt1_2.setAttribute({visible: true});
        txt2_2.setAttribute({visible: true});
        beta.setAttribute({visible: true});

        /*
        if(PtA.X() == PtB.X() || PtA.Y() == PtB.Y()){
            pol_2.setAttribute({visible: false, borders:{visible:false}});
        }
        else{
            pol_2.setAttribute({visible: true, borders:{visible:true}});

        }
        */
    }
    if(i==2){
        pol_1.setAttribute({visible: true, borders:{visible:true}});
        pol_2.setAttribute({visible: true, borders:{visible:true}});
        pol_3.setAttribute({visible: true, borders:{visible:true}});
        txt1_1.setAttribute({visible: true});
        txt2_1.setAttribute({visible: true});
        txt1_2.setAttribute({visible: true});
        txt2_2.setAttribute({visible: true});
        txt1_3.setAttribute({visible: true});
        txt2_3.setAttribute({visible: true});
        beta.setAttribute({visible: true});

    }

    if(i==3){
        pol_1.setAttribute({visible: true, borders:{visible:true}});
        pol_2.setAttribute({visible: true, borders:{visible:true}});
        pol_3.setAttribute({visible: true, borders:{visible:true}});
        pol_4.setAttribute({visible: true, borders:{visible:true}});

        txt1_1.setAttribute({visible: true});
        txt2_1.setAttribute({visible: true});
        txt1_2.setAttribute({visible: true});
        txt2_2.setAttribute({visible: true});
        txt1_3.setAttribute({visible: true});
        txt2_3.setAttribute({visible: true});
        txt1_4.setAttribute({visible: true});
        txt2_4.setAttribute({visible: true});
        beta.setAttribute({visible: true});

    }

    if(i==4){
        pol_1.setAttribute({visible: true, borders:{visible:true}});
        pol_2.setAttribute({visible: true, borders:{visible:true}});
        pol_3.setAttribute({visible: true, borders:{visible:true}});
        pol_4.setAttribute({visible: true, borders:{visible:true}});
        pol_5.setAttribute({visible: true, borders:{visible:true}});

        txt1_1.setAttribute({visible: true});
        txt2_1.setAttribute({visible: true});
        txt1_2.setAttribute({visible: true});
        txt2_2.setAttribute({visible: true});
        txt1_3.setAttribute({visible: true});
        txt2_3.setAttribute({visible: true});
        txt1_4.setAttribute({visible: true});
        txt2_4.setAttribute({visible: true});
        txt1_5.setAttribute({visible: true});
        txt2_5.setAttribute({visible: true});
        beta.setAttribute({visible: true});
        alpha2.setAttribute({visible: true});

        txt1_6.setAttribute({visible: true});
        txt2_6.setAttribute({visible: true});
        txt3_6.setAttribute({visible: true});
        txt4_6.setAttribute({visible: true});
        txt5_6.setAttribute({visible: true});
        txt6_6.setAttribute({visible: true});
        txt7_6.setAttribute({visible: true});
        txt8_6.setAttribute({visible: true});
        txt9_6.setAttribute({visible: true});
        txt10_6.setAttribute({visible: true});
    }
}
function eraseEverything(){
    pol_1.setAttribute({visible: false, borders:{visible:false}});
    pol_2.setAttribute({visible: false, borders:{visible:false}});
    pol_3.setAttribute({visible: false, borders:{visible:false}});
    pol_4.setAttribute({visible: false, borders:{visible:false}});
    pol_5.setAttribute({visible: false, borders:{visible:false}});

    txt1_1.setAttribute({visible: false});
    txt2_1.setAttribute({visible: false});
    txt1_2.setAttribute({visible: false});
    txt2_2.setAttribute({visible: false});
    txt1_3.setAttribute({visible: false});
    txt2_3.setAttribute({visible: false});
    txt1_4.setAttribute({visible: false});
    txt2_4.setAttribute({visible: false});
    txt1_5.setAttribute({visible: false});
    txt2_5.setAttribute({visible: false});
    beta.setAttribute({visible: false});
    alpha2.setAttribute({visible: false});

    txt1_6.setAttribute({visible: false});
    txt2_6.setAttribute({visible: false});
    txt3_6.setAttribute({visible: false});
    txt4_6.setAttribute({visible: false});
    txt5_6.setAttribute({visible: false});
    txt6_6.setAttribute({visible: false});
    txt7_6.setAttribute({visible: false});
    txt8_6.setAttribute({visible: false});
    txt9_6.setAttribute({visible: false});
    txt10_6.setAttribute({visible: false});
}
redo.on('down',function(){
    i = i+1;
    if (i > maxSteps){
        i = maxSteps;
    }
    updateSteps();
});

undo.on('down',function(){
    i=i-1;
    if (i < 0){
        i = 0;
    }
    eraseEverything();
    updateSteps();
});
    //graph.unsuspendUpdate();
                      },
              }
export default Boxes;
